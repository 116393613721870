jQuery(function (){

    jQuery(".js-search_property_button").on("click",function (e){
        e.preventDefault();

       if(!jQuery(this).hasClass("open")){

           jQuery(".js-search_property").show();
           jQuery(this).addClass("open")
           jQuery(this).text("検索フォームを閉じる")
       }
        else if(jQuery(this).hasClass("open")){
            jQuery(".js-search_property").hide();
            jQuery(this).removeClass("open")
            jQuery(this).text("検索フォームを開く")
        }

    });

});

jQuery(function (){

   jQuery("#order_wrap").find("a").each(function (index){
       if(jQuery(this).attr("href")==location.href){
           jQuery(this).addClass("select_order");
       }
   });


});